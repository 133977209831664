import styled from 'styled-components';
import {
  PRIMARY_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../../constants';
import { EngineType, GenericSetState } from '../../../types';
import {
  getEngineNameFromModelId,
  getExampleImageSrc,
} from '../../../utils/engineHelpers';
import { FaImages } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../../pathNames';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { useImagesContext } from '../../../context/ImagesContextProvider';

interface Props {
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
  setShowModal: GenericSetState<boolean>;
  isSelected: boolean;
}
const EngineCard = ({ engine, setEngine, isSelected, setShowModal }: Props) => {
  const navigate = useNavigate();
  const { loggedInUser } = useLoggedInUserContext();
  const {setSelectedEngine} = useImagesContext();
  const { isGoldOnly, isNew } = engine;

  const handleChooseEngine = () => {
    if (engine.isOffline) {
      if (loggedInUser?.username !== 'alpo') {
        alert('This engine is currently offline. Apologies! 🙏');
        return;
      }
    }
    const recentlyUsedEngines = JSON.parse(
      localStorage.getItem('recentlyUsedEngines') || '[]',
    );
    const newRecentlyUsedEngines = [
      engine,
      ...recentlyUsedEngines.filter(
        (e: EngineType) => e.modelId !== engine.modelId,
      ),
    ];
    localStorage.setItem(
      'recentlyUsedEngines',
      JSON.stringify(newRecentlyUsedEngines),
    );

    setEngine(engine);
    setShowModal(false);
  };

  //const engineDescription = getEngineDescription(engine);

  const engineName = getEngineNameFromModelId(engine.modelId);

  const handleShowFeed = () => {
    setSelectedEngine(engineName);
    navigate(`${FEED_PATH}`);
  };

  if (engine.isOffline) {
    return null;
  }

  return (
    <OuterContainer>
      <CardContainer isSelected={isSelected} onClick={handleChooseEngine}>
        <EngineName>
          {isGoldOnly && '⭐ '}
          {isNew && '🆕 '}
          {engineName}
        </EngineName>
        <ExampleImage engine={engine} />
      </CardContainer>
      <ShowFeedButton onClick={handleShowFeed}>
        <FaImages color={PRIMARY_COLOR} />
        Show examples
      </ShowFeedButton>
    </OuterContainer>
  );
};

interface CardContainer {
  isSelected: boolean;
}

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ShowFeedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8px;

  font-size: 14px;
  width: 99%;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 4px;

  gap: 8px;

  padding: 2px;

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border: 1px solid ${PRIMARY_COLOR};
    }
  }
`;

const CardContainer = styled.div<CardContainer>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  width: 120px;
  height: 150px;

  border: ${(props) =>
    `1px solid ${props.isSelected ? PRIMARY_COLOR : BORDER_COLOR}`};
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  background-color: transparent;
  color: ${BORDER_COLOR};

  gap: 4px;

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border: 1px solid ${PRIMARY_COLOR};
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OfflineText = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.5); // black background with 50% opacity
  color: white; // white text color
`;

interface ExampleImageProps {
  engine: EngineType;
}

const ExampleImage = ({ engine }: ExampleImageProps) => {
  const imageSrc = getExampleImageSrc(engine);
  return <StyledExampleImage src={imageSrc} />;
};

const StyledExampleImage = styled.img`
  max-width: 100%;
  max-height: 80%;
  width: auto;
  height: auto;
  display: block;
  border-radius: 4px;
  object-fit: contain;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
  margin-right: 8px;
  white-space: normal;
  word-wrap: break-word;

  min-height: 150px;
  max-height: 150px;
`;

const EngineName = styled.div`
  font-style: bold;
  color: ${PRIMARY_TEXT_COLOR};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EngineDescription = styled.div`
  font-size: 12px;
  color: ${SECONDARY_TEXT_COLOR};
`;

export default EngineCard;
