import { useState, useEffect } from "react";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { FakeImage } from "../../types";
import useRemoveImageFromSaved from "./useRemoveImageFromSaved";
import useSaveImage from "./useSaveImage";
import { useModalsContext } from "../../context/ModalsContextProvider";

interface SaveImageLogicProps {
  image: FakeImage;
  handleSaveIsClicked?: () => void;
  sharedImageRefId?: string;
  triggerToast: () => void;
}

/**
 * Custom hook for saving images and managing save state.
 *
 * This hook is responsible for handling the logic of saving images and managing the save state.
 * It keeps track of whether an image is saved or not, and provides a function to toggle the save state.
 * Additionally, it handles user authentication and navigation to the profile page if the user is not logged in.
 * It takes as a prop triggerToast, which is a function that triggers a toast notification when an image is saved.
 *
 * @param {SaveImageLogicProps} props - The hook props.
 * @returns {SaveImageLogicResult} The hook result.
 */
export const useSaveImageLogic = ({
  image,
  handleSaveIsClicked,
  sharedImageRefId,
  triggerToast,
}: SaveImageLogicProps) => {
  const [isSaved, setIsSaved] = useState(false);
  const [showSaveToFolderModal, setShowSaveToFolderModal] = useState(false);
  const { loggedInUser } = useLoggedInUserContext();
  const { setIsAuthModalOpen} = useModalsContext();
  const saveImage = useSaveImage();
  const removeImageFromSaved = useRemoveImageFromSaved();

  useEffect(() => {
    if (!loggedInUser) {
      setIsSaved(false);
      return;
    }

    const trackIds = loggedInUser.savedImageTrackIds;
    setIsSaved(trackIds?.some((trackId) => trackId === image.trackId) || false);
  }, [image, loggedInUser]);

  const handleSaveImage = () => {
    if (handleSaveIsClicked && typeof handleSaveIsClicked === "function") {
      // this is a hack to move feed when save is clicked. Hopefully not needed in the future
      handleSaveIsClicked();
    }

    if (!loggedInUser) {
      alert("You need to log in to save images");
      setIsAuthModalOpen(true);
      return;
    }

    if (!image.prompt) {
      console.log("no image to save");
      //return;
    }

    if (!isSaved) {
      triggerToast();
      saveImage(image, sharedImageRefId);
      setIsSaved(true);
    } else {
      removeImageFromSaved(image);
      setIsSaved(false);
    }
  };

  return {
    isSaved,
    showSaveToFolderModal,
    setShowSaveToFolderModal,
    handleSaveImage,
  };
};
