import { FaMagic } from 'react-icons/fa';
import { RiSettingsLine } from 'react-icons/ri';
import styled from 'styled-components';
import { StyledButton } from '../../components/common/StyledButton';
import { PRIMARY_COLOR, BORDER_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { CreatePageTab } from '../../types';

interface Tabs {
  selectedTab: CreatePageTab;
  handleTabChange: (tab: CreatePageTab) => void;
}

const CreatePageTabs = ({ selectedTab, handleTabChange }: Tabs) => {
  const magicText = 'Easy mode';

  const smallScreen = window.innerWidth < 380;
  const advancedText = smallScreen ? 'Advanced' : 'Advanced mode';
  return (
    <TabsContainer>
      <TabButton
        isSelected={selectedTab === 'magic'}
        onClick={() => handleTabChange('magic')}
      >
        <FaMagic /> {magicText}
      </TabButton>
      <TabButton
        isSelected={selectedTab === 'advanced'}
        onClick={() => handleTabChange('advanced')}
      >
        <RiSettingsLine />
        {advancedText}
      </TabButton>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 16px;

  ${desktopMediaQuery} {
    justify-content: flex-start;
    margin-left: 30vw;
  }
`;

const TabButton = styled(StyledButton)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  width: 200px;
  transition: 0.3s;
  border-color: ${({ isSelected }) =>
    isSelected ? PRIMARY_COLOR : BORDER_COLOR};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  &:hover {
    border-color: ${PRIMARY_COLOR};
    border-width: 1px;
    margin-bottom: 0px;
  }
`;

export default CreatePageTabs;
