import { EngineType } from '../types';

const engineList: EngineType[] = [
  {
    name: 'Realistic HD 2',
    modelId: 'realistic_vision_v5.1',
    type: 'realistic',
  },
  {
    name: 'Realistic Porn',
    modelId: 'uber-realistic-porn-merge',
    type: 'realistic',
  },
  {
    name: 'Realistic HD',
    modelId: 'realistic_vision_v4',
    type: 'realistic',
  },
  {
    name: 'Realistic 1',
    modelId: 'realistic-vision-v13',
    type: 'realistic',
    isGoldOnly: true,
  },
  {
    name: 'Realistic 2',
    modelId: 'hardblend',
    type: 'realistic',
    isGoldOnly: true,
  },
  {
    name: 'Easy Realistic 2',
    modelId: 'absoluteReality_v1.6',
    type: 'realistic',
    isOffline: true,
  },
  {
    name: 'Realistic 3',
    modelId: 'realistic-vision-14',
    type: 'realistic',
    isOffline: true,
  },
  {
    // TO BE REMOVED
    name: 'Easy Realistic',
    modelId: 'reliberate_v2',
    type: 'realistic',
    isOffline: true,
  },
  {
    name: 'Hentai Gold',
    modelId: 'aom_v3',
    type: 'anime',
  },
  {
    name: 'Hentai 3',
    modelId: 'anything_v5', // TODO: ehk väärä malli
    type: 'anime',
  },
  {
    name: 'Furry 2',
    modelId: 'yiffymix_v3.3',
    type: 'anime',
  },
  {
    name: 'Hentai 2',
    modelId: 'anything-v3',
    type: 'anime',
    isGoldOnly: true,
  },
  {
    name: 'Furry',
    modelId: 'furry',
    type: 'anime',
    isGoldOnly: true,
  },
  {
    // TO BE REMOVED
    name: 'Hentai 1',
    modelId: 'anything-v4',
    type: 'anime',
    isOffline: true,
  },
  {
    name: 'Digital 1',
    modelId: 'perfect-world',
    type: 'digital',
  },
  {
    name: 'Digital Babes',
    modelId: 'babes_v2',
    type: 'digital',
  },
  {
    name: 'Digital 3',
    modelId: 'ned_v1.22',
    type: 'digital',
    isGoldOnly: true,
  },
  {
    name: 'Digital 2',
    modelId: 'chilloutmix',
    type: 'digital',
    isGoldOnly: true,
  },
];

export const getEngineNameFromModelId = (modelId: string) =>
  engineList.find((engine) => engine.modelId === modelId)?.name ??
  'Realistic 1';

export const transformOldEngineToNew = (
  engineToTransform: EngineType,
): EngineType => {
  const isNewerEngineType =
    getEngineModelId(engineToTransform.name) !== undefined;

  const newestEngine = engineList.find(
    (engine) => engine.modelId === engineToTransform.modelId,
  );

  if (newestEngine) {
    return newestEngine;
  }

  if (isNewerEngineType) {
    return engineToTransform;
  }
  const defaultRealistic = {
    name: 'Realistic 1',
    modelId: 'realistic-vision-v13',
  };

  const defaultAnime = {
    name: 'Hentai 1',
    modelId: 'anything-v4',
  };

  switch (engineToTransform.name) {
    case 'Photorealistic (F222)':
      return defaultRealistic;
    case 'Photorealistic 2 (Realistic Vision v1.3)':
      return defaultRealistic;
    case 'Photorealistic 3 (Dreamlike Photoreal 2.0)':
      return defaultRealistic;
    case 'Anime (anything V3)':
      return defaultAnime;
    case 'Anime (Waifu diffusion)':
      return defaultAnime;
    case 'Photorealistic (Protogen)':
      return defaultRealistic;
    case 'Vintedoi (very realistic 3d art)':
      return defaultRealistic;
    case '3d art (Redshift)':
      return defaultRealistic;
    case 'Modelshoot':
      return defaultRealistic;
    case 'Analog':
      return defaultRealistic;
    case 'Midjourney v4':
      return defaultRealistic;
    case 'Synthwave punk (SFW)':
      return defaultRealistic;
    default:
      return defaultRealistic;
  }
};

export const getEngineNames = () => engineList.map((engine) => engine.name);

export const getEngines = () => engineList;

export const getEngineUsingModelId = (modelId: string): EngineType =>
  engineList.find((engine) => engine.modelId === modelId) ?? engineList[0];

// on local storage
export const getRecentlyUsedEngines = (): EngineType[] => {
  const recentlyUsed = localStorage.getItem('recentlyUsedEngines');
  if (recentlyUsed) {
    // take the modelId and refresh the list from engineList
    const refreshedRecentlyUsed = JSON.parse(recentlyUsed).map(
      (engine: EngineType) => {
        const refreshedEngine = engineList.find(
          (engineFromList) => engineFromList.modelId === engine.modelId,
        );
        if (refreshedEngine) {
          return refreshedEngine;
        }
        return engine;
      },
    );
    return refreshedRecentlyUsed;
  }
  return [];
};

export const getEngineModelId = (name: string) =>
  engineList.find((engine) => engine.name === name)?.modelId;

export const getEnginesByType = (type: 'realistic' | 'anime' | 'digital') =>
  engineList.filter((engine) => engine.type === type && !engine.isOffline);

export const getEngineNamesByType = (type: 'realistic' | 'anime' | 'digital') =>
  getEnginesByType(type).map((engine) => engine.name);

export const getEngineTypeWithModelId = (modelId: string) =>
  engineList.find((engine) => engine.modelId === modelId)?.type ?? 'realistic';

export const getExampleImageSrc = (engine: EngineType) => {
  switch (engine.modelId) {
    case 'realistic-vision-v13':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1692160890-d1cb3b5a-b1d7-4ca0-aef6-863c2c49fb96-sd.png';
    case 'realistic-vision-14':
      return 'https://d12mvgqqknpvkx.cloudfront.net/222b3c82-9d22-4e6e-9197-ea04636f0d38-0.png';
    case 'uber-realistic-porn-merge':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/b6a60344-84d1-4f7e-a6be-b7acbe0f4ce7.png';
    case 'hardblend':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/fa012179-4fb5-460c-ad82-70ddb7ab629f.png';
    case 'realistic-men':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/d6d8cb45-0a75-4df5-a0cb-52b0e8f0e762.png';
    case 'anything-v4':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/35ca6878-330d-4829-a3db-2e85a33093e4.png';
    case 'anything-v3':
      return 'https://d12mvgqqknpvkx.cloudfront.net/ffd947e3-8ae3-4a66-9734-72c58fb9edb1-0.png';
    case 'furry':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/33512744-ca17-4ea6-aa36-005cdd36f1f8.png';
    case 'perfect-world':
      return 'https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/aba688c9-a20f-4d79-8570-4e9cac35f61e-0.png';
    case 'chilloutmix':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/33e92e02-0266-499e-8e61-94dac7900561.png';
    case 'ned_v1.22':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693983917-28fbe9e3-8dbf-44a8-bd74-a28e5dd15bd5-sd.png';
    case 'reliberate_v2':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693984263-a4c60bae-b3b6-4e01-bfc8-6407b809336c-sd.png';
    case 'babes_v2':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693984540-f981a289-e17b-4123-a58b-4071ea44c36b-sd.png';
    case 'realistic_vision_v4':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693984957-42da2cff-024f-447e-a952-d187d944db05-sd.png';
    case 'realistic_vision_v5.1':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693985088-bb5134e9-34b9-4739-8d17-a81bfd428405-sd.png';
    case 'aom_v3':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/1693985327-04c3e85f-bae4-46e1-8c02-a24587ec64f3-sd.png';
    case 'anything_v5':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/b9dc7d01-57ff-4439-bd69-7f7785ade0cf.png';
    case 'yiffymix_v3.3':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/4b28f87b-b669-414c-8459-9b732de0b259.png';
    case 'absoluteReality_v1.6':
      return 'https://serve-model-sd-outputs.s3.amazonaws.com/26ff881c-bdd0-4585-b7f0-ffe41fd9a08a.png';
  }
  return 'https://serve-model-sd-outputs.s3.amazonaws.com/1692160890-d1cb3b5a-b1d7-4ca0-aef6-863c2c49fb96-sd.png';
};

export const getEngineDescription = (engine: EngineType) => {
  switch (engine.modelId) {
    case 'realistic-vision-v13':
      return "Old 'Realistic women 1'. Good for creating realistic women, but also anything realistic-looking";
    case 'uber-realistic-porn-merge':
      return 'Excellent for porn images, but works for anything realistic-looking';
    case 'realistic-vision-14':
      return "Old 'Realistic women 2'. A similar engine to R1, but may need more specific prompts to get good results";
    case 'hardblend':
      return "Old 'Realistic 3'. Creates hyperrealistic images, that might look slightly digital";
    case 'furry':
      return 'Good for creating furry characters. Also excels in digital and drawn images';
    case 'anything-v4':
      return "Old 'HD Hentai'. Good for creating hentai images, but also anything drawn. Creates more 'modern' images.";
    case 'anything-v3':
      return "Old 'Hentai'. More old-schoolish drawing style";
    case 'perfect-world':
      return "Old 'Digital porn/nudes'. Great for digital or hyperrealistic images. More towards porn";
    case 'chilloutmix':
      return "Old 'Realistic, digital-like'. Similar to digital 1, more digital-like";
    case 'ned_v1.22':
      return "Leans towards 'drawn-digital'. Great at cosplay, anime, realistic people, 'booru'-tags & animals";
    case 'reliberate_v2':
      return 'Fairly simple to prompt. Excellent at photography-style images';
    case 'babes_v2':
      return "Realistic digital images. Important to add 'quality enhancers' to the prompt, e.g. ((masterpiece))";
    case 'realistic_vision_v4':
      return 'The next version of the realistic engine. Similar to R1';
    case 'realistic_vision_v5.1':
      return 'The better Gold version of Realistic HD';
    case 'aom_v3':
      return 'Simple to prompt. Illustrations with very realistic textures and can generate a wide variety of content';
    case 'anything_v5':
      return 'Generates similar images to hentai 2, but better';
    case 'yiffymix_v3.3':
      return 'Not only for furry! Very versatile engine capable of digital, drawn, and furry images, or just about anything';
    case 'absoluteReality_v1.6':
      return 'Slightly digital-leaning images. Use simple short prompts for best results';
  }
  return 'No description available.';
};

export {};
