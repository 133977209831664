import { useState } from 'react';
import { FaRegComment } from 'react-icons/fa6';
import styled from 'styled-components';
import { SharedImage } from '../../../types';
import { desktopMediaQuery } from '../../../styleHelpers';
import { PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../../constants';
import CommentsModal from '../../../components/Modals/CommentsModal/CommentsModal';

interface CommentsButtonAndModalProps {
  image: SharedImage;
}

const CommentsButtonAndModal: React.FC<CommentsButtonAndModalProps> = ({
  image,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  console.log(image);

  const amountOfComments = image.comments.length;

  return (
    <>
      <IconContainer>
        <FaRegComment color={'white'} size={22} onClick={toggleModal} />
        {amountOfComments}
      </IconContainer>

      <CommentsModal
        show={isModalOpen}
        setShow={setIsModalOpen}
        image={image}
      />
    </>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-left: 4px;
  margin-right: -4px;

  font-size: 16px;

  color: ${SECONDARY_TEXT_COLOR};
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${PRIMARY_COLOR};
    }
  }
`;

export default CommentsButtonAndModal;
