import styled from 'styled-components';
import {
  GOLD_COLOR,
  PICARUS_URL,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { GenericSetState } from '../../../types';
import { StyledButton } from '../../common/StyledButton';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { BiCoin } from 'react-icons/bi';
import { useEffect } from 'react';
import {
  BenefitListItem,
  ContainerPaymentModal,
  LineInBuyModal,
  Modal,
  ModalText,
  paymentModalContentStyles,
  paymentModalOverlayStyles,
} from './PaymentCommonComponents';
import { MdPayment } from 'react-icons/md';
import { useCookies } from 'react-cookie';
import useHandleGetUser from '../../../hooks/userHandling/useHandleGetUser';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  amountOfCredits: number;
}

export const PAYMENTS_DOWN = true;

type PaymentTypes = 'small-credits' | 'medium-credits' | 'large-credits';

const BuyCreditsModal = ({ showModal, setShowModal, amountOfCredits }: I) => {
  const { loggedInUser } = useLoggedInUserContext();
  const [cookies] = useCookies(['token']);

  const handleGetUser = useHandleGetUser();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleBuyCredits = (paymentType: PaymentTypes) => {
    if (PAYMENTS_DOWN) {
      alert('Payments are down, please try again later');
      return;
    }
    const username = loggedInUser?.username;
    if (!username) {
      alert('You must be logged in to buy credits');
      return;
    }
    const USE_GUMROAD = true;
    const urlToRedirect = USE_GUMROAD
      ? `${PICARUS_URL}/redirect?username=${username}&paymentType=${paymentType}`
      : `${PICARUS_URL}/redirect?username=${username}&paymentType=${paymentType}`;
    window.location.href = urlToRedirect;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (showModal) {
      const token = cookies.token;
      if (token) {
        intervalId = setInterval(async () => {
          handleGetUser(token);
        }, 3000);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showModal]);

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: paymentModalOverlayStyles,
        content: paymentModalContentStyles,
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerPaymentModal>
        <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
        <CurrentCredits amountOfCredits={amountOfCredits} />
        <Content handleBuyCredits={handleBuyCredits} />
      </ContainerPaymentModal>
    </Modal>
  );
};

const CurrentCredits = ({ amountOfCredits }: { amountOfCredits: number }) => (
  <CurrentCreditsContainer>
    <b>Credits:</b>
    <BiCoin size={20} color={GOLD_COLOR} style={{ marginLeft: 8 }} />
    {'x '}
    {amountOfCredits}
  </CurrentCreditsContainer>
);

const CurrentCreditsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

type ContentProps = {
  handleBuyCredits: (paymentType: PaymentTypes) => void;
};
const Content = ({ handleBuyCredits }: ContentProps) => (
  <>
    <ContentContainer>
      <BenefitListItem>Fast & Turbo generation</BenefitListItem>
      <BenefitListItem>Multiple images at once</BenefitListItem>
      <BenefitListItem>Up to 75% cheaper than competitors</BenefitListItem>

      <LineInBuyModal />

      <PricingTextSmall />
      <BuyCreditsButton onClick={() => handleBuyCredits('small-credits')}>
        <MdPayment color={GOLD_COLOR} /> Buy now $4.9
      </BuyCreditsButton>

      <LineInBuyModal />

      <PricingTextMed />
      <BuyCreditsButton onClick={() => handleBuyCredits('medium-credits')}>
        <MdPayment color={GOLD_COLOR} /> Buy now $9.9
      </BuyCreditsButton>

      <LineInBuyModal />

      <PricingTextBig />
      <BuyCreditsButton onClick={() => handleBuyCredits('large-credits')}>
        <MdPayment color={GOLD_COLOR} /> Buy now $19.9
      </BuyCreditsButton>
    </ContentContainer>
  </>
);

const ContentContainer = styled(ModalText)`
  width: 90%;
  padding-bottom: 40px;
`;

const PricingTextSmall = () => (
  <OuterPricingContainer>
    <StyledDiv>
      <StyledPrice>$4.9</StyledPrice>
      <StyledCredits>/ 300 credits</StyledCredits>
    </StyledDiv>
    <Taxes>(+ taxes)</Taxes>
  </OuterPricingContainer>
);

const PricingTextMed = () => (
  <OuterPricingContainer>
    <SaveText>Popular - Save 25%!</SaveText>
    <StyledDiv>
      <StyledPrice>$9.9</StyledPrice>
      <StyledCredits>/ 800 credits</StyledCredits>
      <StyledPrice></StyledPrice>
    </StyledDiv>
    <Taxes>(+ taxes)</Taxes>
  </OuterPricingContainer>
);

const PricingTextBig = () => (
  <OuterPricingContainer>
    <SaveText>Best deal - Save 33%!</SaveText>
    <StyledDiv>
      <StyledPrice>$19.9</StyledPrice>
      <StyledCredits>/ 1800 credits</StyledCredits>
      <StyledPrice></StyledPrice>
    </StyledDiv>
    <Taxes>(+ taxes)</Taxes>
  </OuterPricingContainer>
);

const OuterPricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  margin-top: 16px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: row;
`;

const StyledPrice = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

const StyledCredits = styled.p`
  font-size: 16px;
`;

const Taxes = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${PRIMARY_TEXT_COLOR};
  margin-top: -24px;
  margin-bottom: 16px;
`;

const SaveText = styled.div`
  font-size: 16px;
  margin-bottom: -16px;
  font-weight: bold;
  color: green;
`;

const BuyCreditsButton = styled(StyledButton)`
  border-color: ${GOLD_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 0;
`;

export default BuyCreditsModal;
