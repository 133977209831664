import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../constants';
import { GenericSetState, LoginDetails } from '../../../types';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import LoginOrRegisterForm, {
  Variant,
} from '../../../pages/ProfilePage/LoginOrRegisterForm';
import useHandleLogin from '../../../hooks/userHandling/useHandleLogin';
import useHandleRegister from '../../../hooks/userHandling/useHandleRegister';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import useHandleGetUser from '../../../hooks/userHandling/useHandleGetUser';
import { useModalsContext } from '../../../context/ModalsContextProvider';
import { toast } from 'react-toastify';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const AuthModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  const { authModalVariant, setAuthModalVariant } = useModalsContext();

  const handleGetUser = useHandleGetUser();

  const { loggedInUser, isLoadingLogin, setIsLoadingLogin } =
    useLoggedInUserContext();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = useHandleLogin();
  const handleRegister = useHandleRegister();

  useEffect(() => {
    if (loggedInUser) {
      setShowModal(false);
    }
  }, [loggedInUser]);

  const handleSend = async (loginDetails: LoginDetails) => {
    setIsLoadingLogin(true);
    if (!loginDetails.username) {
      alert('Username is required');
      setIsLoadingLogin(false);
      return;
    }

    if (!loginDetails.password) {
      alert('Password is required');
      setIsLoadingLogin(false);
      return;
    }

    if (authModalVariant === 'login') {
      const result = await handleLogin(loginDetails);
      setIsLoadingLogin(false);

      if (result.status === 401) {
        alert('Incorrect username or password');
        return;
      }

      if (result.status === 200) {
        setUsername('');
        setPassword('');

        handleGetUser(result.token as string);
        setShowModal(false);
        return;
      }
    }

    if (authModalVariant === 'register') {
      if (loginDetails.username.length <= 4) {
        alert('Username must more than 4 characters long');
        setIsLoadingLogin(false);
        return;
      }

      if (loginDetails.password.length <= 5) {
        alert('Password must at least 5 characters long');
        setIsLoadingLogin(false);
        return;
      }
      const result = await handleRegister(loginDetails);
      setIsLoadingLogin(false);

      if (result.status === 409) {
        alert('Username already taken');
        return;
      }

      if (result.status === 200) {
        setUsername('');
        setPassword('');
        handleGetUser(result.token as string);
        toast.success('Account created successfully');
        setShowModal(false);

        return;
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '16px',
            paddingRight: '16px',
            width: '90vw',
            maxHeight: '90vh',
            maxWidth: '550px',
            margin: 'auto',
            marginTop: '15vh',
            paddingBottom: '36px',
            paddingTop: '36px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal onClick={handleClose} />

        <LoginOrRegisterForm
          variant={authModalVariant}
          handleSend={handleSend}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          isLoadingLoggingIn={isLoadingLogin}
        />
        {authModalVariant === 'login' ? (
          <ShowRegisterButton setVariant={setAuthModalVariant} />
        ) : (
          <ShowLoginButton setVariant={setAuthModalVariant} />
        )}
      </Modal>
    </>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ButtonProps = {
  setVariant: GenericSetState<Variant>;
};

const ShowRegisterButton = ({ setVariant }: ButtonProps) => {
  return (
    <TextButton onClick={() => setVariant('register')}>
      Don't have an account? Register
    </TextButton>
  );
};

const ShowLoginButton = ({ setVariant }: ButtonProps) => {
  return (
    <TextButton onClick={() => setVariant('login')}>
      Already have an account? Login
    </TextButton>
  );
};

const TextButton = styled.button`
  background: none;
  border: none;
  color: #1da1f2;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
`;

export default AuthModal;
