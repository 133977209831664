import { getBaseUrlForFetching } from '../../App';

const useGetFeed = () => {
  const headers = {
    'Content-type': 'Application/json',
  };
  const baseUrl = getBaseUrlForFetching();

  const getBestForLandingPage = async (): Promise<string[]> => {
    const functionName = 'getBestImagesForLandingPage';

    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers,
    });

    if (response.status !== 200) {
      throw new Error('failed to fetch');
    }

    const imageUrls = await response.json();
    return imageUrls;
  };

  return { getBestForLandingPage };
};

export default useGetFeed;
