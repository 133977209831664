import { getBaseUrlForFetching } from "../../App";
import { LoginDetails } from "../../types";
import { trackNewSignup } from "../../utils/analytics";
import { headers } from "../misc/helpers";
import useSetUserCookie from "./useSetUserCookie";

type ResultOfRegistering = {
  status: number;
  token?: string;
};

type RegisterDetails = LoginDetails & {
  referralId?: string;
  fingerprint?: string;
};

const useHandleRegister = () => {
  const setUserCookie = useSetUserCookie();

  const handleRegister = async (
    registerDetails: RegisterDetails
  ): Promise<ResultOfRegistering> => {
    const functionName = "register";
    const baseUrl = getBaseUrlForFetching();
    const registerUrl = `${baseUrl}/${functionName}`;

    const referralId = localStorage.getItem("referralId");
    if (referralId) {
      registerDetails.referralId = referralId;
    }

    const response = await fetch(registerUrl, {
      method: "POST",
      body: JSON.stringify(registerDetails),
      headers,
    });

    if (response.status === 409) {
      return { status: 409 };
    }

    const data = await response.json();

    if (data.error) {
      console.log(data.error);
      return { status: 500 };
    }

    const token = data?.token;
    if (token) {
      setUserCookie(token);
      trackNewSignup();
      return { status: 200, token };
    } else {
      return { status: 500 };
    }
  };
  return handleRegister;
};

export default useHandleRegister;
