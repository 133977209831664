import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FakeImage, GenericSetState, SharedImage } from '../../../types';
import ImageInFeedCard from './ImageInFeedCard';
import { Carousel } from 'react-responsive-carousel';
import { desktopMediaQuery } from '../../../styleHelpers';

interface AlbumInFeedCardProps {
  sharedImage: SharedImage;
  handleUpvotingImage: (image: SharedImage) => void;
  handleImageLoadingError: () => void;
  setSelectedImageInAlbum: GenericSetState<FakeImage | null>;
}

const AlbumInFeedCard = ({
  sharedImage,
  handleUpvotingImage,
  handleImageLoadingError,
  setSelectedImageInAlbum,
}: AlbumInFeedCardProps) => {
  if (!sharedImage || !sharedImage.album) return null;

  const albumImages = sharedImage.album;
  const [selectedItem, setSelectedItem] = useState(0);

  const [activeImageOnCarousel, setActiveImageOnCarousel] = useState(
    albumImages[selectedItem],
  );

  const activeImageHeight = activeImageOnCarousel?.height;
  const [carouselHeight, setCarouselHeight] = useState(
    activeImageHeight ?? 512,
  );

  useEffect(() => {
    if (activeImageHeight) {
      setCarouselHeight(activeImageHeight);
    }
  }, [activeImageOnCarousel, activeImageHeight]);

  function handleChange(selectedIndex: number) {
    const newActiveIndex = selectedIndex;
    setSelectedItem(newActiveIndex);

    const newActiveImage = albumImages[newActiveIndex];

    if (!newActiveImage) {
      return;
    }

    setActiveImageOnCarousel(newActiveImage);

    const activeImageHeight = newActiveImage?.height;

    setCarouselHeight(activeImageHeight ?? 512);

    setSelectedImageInAlbum(newActiveImage);
  }

  return (
    <AlbumInFeedCardContainer>
      <StyledImageCarousel
        height={carouselHeight}
        showIndicators={albumImages.length < 7}
        showThumbs={false}
        showStatus={false}
        selectedItem={selectedItem}
        emulateTouch
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        onChange={handleChange}
        useKeyboardArrows={true}
      >
        {albumImages.map((img) => (
          <ImageInFeedCard
            imageUrl={img.imageUrl}
            handleUpvotingImage={() => handleUpvotingImage(sharedImage)}
            handleImageLoadingError={handleImageLoadingError}
            key={img.trackId}
          />
        ))}
      </StyledImageCarousel>
    </AlbumInFeedCardContainer>
  );
};

const AlbumInFeedCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98vw;

  margin-bottom: 4px;

  min-height: 400px;

  ${desktopMediaQuery} {
    width: 50vw;
    max-width: 768px;
  }
`;

const StyledImageCarousel = styled(Carousel)<{ height: number }>`
`;

export default AlbumInFeedCard;
