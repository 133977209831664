import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CREATE_PATH } from '../../pathNames';
import { StyledButton } from '../../components/common/StyledButton';
import { FaMagic } from 'react-icons/fa';

const CreateImagesCTAButton: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate(CREATE_PATH);
  };

  return (
    <StyledButton onClick={handleCreateClick}>
      <Container>
        Create Images <StyledMagicIcon />
      </Container>
    </StyledButton>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledMagicIcon = styled(FaMagic)``;
export default CreateImagesCTAButton;
