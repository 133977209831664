import { createContext, useContext, useState } from 'react';
import {
  GenericSetState,
  Size,
  EngineType,
  SpeedModeType,
  StyleInterface,
  TagInMagicMode,
} from '../types';
import { getDefaultStyles } from '../utils/imageStylesHelpers';
import { getRandomSeed } from '../components/helpers';
import { DEFAULT_SIZE } from '../constants';

interface ImageGenerationSettingsContext {
  userPrompt: string;
  setUserPrompt: GenericSetState<string>;
  negativePrompt: string;
  setNegativePrompt: GenericSetState<string>;
  seedState: number;
  setSeed: GenericSetState<number>;
  size: Size;
  setSize: GenericSetState<Size>;
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
  cfg: number;
  setCfg: GenericSetState<number>;
  amountToGenerate: number;
  setAmountToGenerate: GenericSetState<number>;
  speedMode: SpeedModeType;
  setSpeedMode: GenericSetState<SpeedModeType>;
  uploadedImageUrl: string;
  setUploadedImageUrl: GenericSetState<string>;
  denoisingStrength: number;
  setDenoisingStrength: GenericSetState<number>;
  selectedStyle: StyleInterface;
  setSelectedStyle: GenericSetState<StyleInterface>;

  showHiddenStyleSettings: boolean;
  setShowHiddenStyleSettings: GenericSetState<boolean>;
  showAllStylesModal: boolean;
  setShowAllStylesModal: GenericSetState<boolean>;
  didClickEditButton: boolean; //edit button on image generation page
  setDidClickEditButton: GenericSetState<boolean>;

  isCreatingVariations: boolean;
  setIsCreatingVariations: GenericSetState<boolean>;

  selectedTags: TagInMagicMode[];
  setSelectedTags: GenericSetState<TagInMagicMode[]>;
}

const realisticHD2 = {
  name: 'Realistic HD 2',
  modelId: 'realistic_vision_v5.1',
  type: 'realistic',
};

const defaultState = {
  userPrompt: '',
  setUserPrompt: undefined as unknown as GenericSetState<string>,
  negativePrompt: '',
  setNegativePrompt: undefined as unknown as GenericSetState<string>,
  seedState: 0,
  setSeed: undefined as unknown as GenericSetState<number>,
  size: DEFAULT_SIZE,
  setSize: undefined as unknown as GenericSetState<Size>,
  engine: realisticHD2 as EngineType,
  setEngine: undefined as unknown as GenericSetState<EngineType>,
  cfg: 7.5,
  setCfg: undefined as unknown as GenericSetState<number>,
  amountToGenerate: 1,
  setAmountToGenerate: undefined as unknown as GenericSetState<number>,
  speedMode: 'normal' as SpeedModeType,
  setSpeedMode: undefined as unknown as GenericSetState<SpeedModeType>,
  uploadedImageUrl: '',
  setUploadedImageUrl: undefined as unknown as GenericSetState<string>,
  denoisingStrength: 5,
  setDenoisingStrength: undefined as unknown as GenericSetState<number>,
  selectedStyle: getDefaultStyles()[0],
  setSelectedStyle: undefined as unknown as GenericSetState<StyleInterface>,

  showHiddenStyleSettings: false,
  setShowHiddenStyleSettings: undefined as unknown as GenericSetState<boolean>,
  showAllStylesModal: false,
  setShowAllStylesModal: undefined as unknown as GenericSetState<boolean>,
  didClickEditButton: false,
  setDidClickEditButton: undefined as unknown as GenericSetState<boolean>,

  isCreatingVariations: false,
  setIsCreatingVariations: undefined as unknown as GenericSetState<boolean>,

  selectedTags: [],
  setSelectedTags: undefined as unknown as GenericSetState<TagInMagicMode[]>,
};

const ImageGenerationSettingsContext =
  createContext<ImageGenerationSettingsContext>(defaultState);

export const useImageGenerationSettingsContext = () =>
  useContext<ImageGenerationSettingsContext>(ImageGenerationSettingsContext);

// @ts-ignore children does actually exist, todo figure types?
const ImageGenerationSettingsContextProvider = ({ children }) => {
  const [userPrompt, setUserPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [seedState, setSeed] = useState(getRandomSeed());
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [engine, setEngine] = useState<EngineType>(realisticHD2 as EngineType);
  const [cfg, setCfg] = useState(7.5);
  const [amountToGenerate, setAmountToGenerate] = useState(1);
  const [speedMode, setSpeedMode] = useState<SpeedModeType>('turbo');
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');
  const [denoisingStrength, setDenoisingStrength] = useState(0.4);
  const [selectedStyle, setSelectedStyle] = useState<StyleInterface>(
    getDefaultStyles()[0],
  );

  const [showHiddenStyleSettings, setShowHiddenStyleSettings] = useState(false);
  const [showAllStylesModal, setShowAllStylesModal] = useState(false);
  const [didClickEditButton, setDidClickEditButton] = useState(true);

  const [isCreatingVariations, setIsCreatingVariations] = useState(false);

  const [selectedTags, setSelectedTags] = useState<TagInMagicMode[]>([]);

  return (
    <ImageGenerationSettingsContext.Provider
      value={{
        userPrompt,
        setUserPrompt,
        negativePrompt,
        setNegativePrompt,
        seedState,
        setSeed,
        size,
        setSize,
        engine,
        setEngine,
        cfg,
        setCfg,
        amountToGenerate,
        setAmountToGenerate,
        speedMode,
        setSpeedMode,
        uploadedImageUrl,
        setUploadedImageUrl,
        denoisingStrength,
        setDenoisingStrength,
        selectedStyle,
        setSelectedStyle,

        showHiddenStyleSettings,
        setShowHiddenStyleSettings,
        showAllStylesModal,
        setShowAllStylesModal,
        didClickEditButton,
        setDidClickEditButton,

        isCreatingVariations,
        setIsCreatingVariations,

        selectedTags,
        setSelectedTags,
      }}
    >
      {children}
    </ImageGenerationSettingsContext.Provider>
  );
};

export default ImageGenerationSettingsContextProvider;
