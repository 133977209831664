import React from 'react';
import { StyledButton } from '../../components/common/StyledButton';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa6';
import { useModalsContext } from '../../context/ModalsContextProvider';

interface LoginButtonProps {}

const LoginButton: React.FC<LoginButtonProps> = () => {
  const { setIsAuthModalOpen } = useModalsContext();
  const onClick = () => {
    setIsAuthModalOpen(true);
  };
  return (
    <StyledButton onClick={onClick}>
      <Container>
        Login
        <FaUser />
      </Container>
    </StyledButton>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export default LoginButton;
