import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { SharedImage } from '../../types';
import { fadeIn } from '../../components/ImageStuff/animations';
import UserNameAndAvatar from '../../components/ImageStuff/UserNameAndAvatar';
import ImageInFeedCard from '../FeedPage/FeedImagePage/ImageInFeedCard';
import { BiUpvote } from 'react-icons/bi';
import useHandleMarkingImageAsSeen from '../../hooks/feed/useHandleMarkingImageAsSeen';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import {
  desktopMediaQuery,
  imageHoverAnimationInFeed,
} from '../../styleHelpers';

interface HomeFeedCardProps {
  image: SharedImage;
  index: number;
  handleClick: (image: SharedImage, index: number) => void;
}

const HomeFeedCard: React.FC<HomeFeedCardProps> = ({
  image,
  index,
  handleClick,
}) => {
  const [hideImage, setHideImage] = useState<boolean>(false);
  const { loggedInUser } = useLoggedInUserContext();

  const handleImageLoadingError = () => {
    setHideImage(true);
  };

  const shouldMarkAsSeen = loggedInUser ? true : false;
  const containerRef = useRef<HTMLDivElement>(null);
  useHandleMarkingImageAsSeen({
    containerRef,
    shouldMarkAsSeen,
    imageId: image.id,
  });

  if (hideImage || !image) {
    return null;
  }
  return (
    <Container ref={containerRef}>
      <NameAndAvatarContainer>
        <UserNameAndAvatar image={image} inHomePage />
      </NameAndAvatarContainer>

      <div onClick={() => handleClick(image, index)}>
        <ImageInFeedCard
          imageUrl={image.image.imageUrl}
          handleUpvotingImage={() => {}}
          handleImageLoadingError={handleImageLoadingError}
          inHomePage
        />
      </div>

      <AmountOfUpvotesContainer>
        <BiUpvote />
        {image.amountOfRemixes}
      </AmountOfUpvotesContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  width: 98%;
  margin-left: 4px;

  margin-top: -62px;

  ${desktopMediaQuery} {
    margin-top: -48px;
    margin-bottom: -32px;
  }

  animation: ${fadeIn} 0.5s ease-in-out;

  ${desktopMediaQuery} {
    max-width: 420px;
    cursor: pointer;
  }
  ${imageHoverAnimationInFeed}
`;

const NameAndAvatarContainer = styled.div`
  width: 99%;
  transform: translateY(42px);
  background-color: #170d282f;
  z-index: 2;
  height: 42px;
  ${desktopMediaQuery} {
    height: 52px;
  }
`;

const AmountOfUpvotesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;

  transform: translateY(-42px);

  gap: 4px;

  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px;
  font-size: 16px;
  border-radius: 0 10px 0 0;
  cursor: default;
`;

export default HomeFeedCard;
