import React, { useState } from 'react';
import { FakeImage, InfoText, SharedImage } from '../../../types';
import styled from 'styled-components';
import SaveButton from '../../../components/common/SaveButton';
import { IoShuffleOutline } from 'react-icons/io5';
import { BiUpvote } from 'react-icons/bi';
import {
  desktopMediaQuery,
  hoverPointerOnDesktop,
} from '../../../styleHelpers';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import ImageInfoModal from '../../../components/Modals/ImageInfoModal';
import useUpdateAmountOfRemixes from '../../../hooks/feed/useUpdateAmountOfRemixes';
import { PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../../constants';
import { fadeIn } from '../../../components/ImageStuff/animations';
import useHandleClickRemix from '../../../hooks/images/useHandleClickRemix';
import CommentsButtonAndModal from './CommentsButtonAndModal';

interface ImageActionsInFeedProps {
  image: SharedImage | null;
  handleUpvotingImage: () => void;
  hasUserLiked: boolean;
  selectedImageInAlbum: FakeImage | null;
}

const ImageActionsInFeed: React.FC<ImageActionsInFeedProps> = ({
  image,
  handleUpvotingImage,
  hasUserLiked,
  selectedImageInAlbum,
}) => {
  const updateAmountOfRemixes = useUpdateAmountOfRemixes();

  const handleRemixingImage = useHandleClickRemix();
  const handleClickRemix = () => {
    if (!image) return;
    const fakeImage = selectedImageInAlbum || image.image;
    fakeImage.remixing = true;
    if (image.image.parentImageTrackId) {
      fakeImage.parentImageTrackId = fakeImage.parentImageTrackId;
    } else {
      fakeImage.parentImageTrackId = fakeImage.trackId;
    }
    updateAmountOfRemixes(image);
    handleRemixingImage(fakeImage);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState<InfoText | undefined>(
    undefined,
  );

  const handleClickInfo = () => {
    if (!image) return;

    if (image?.image.selectedTags && image.image.selectedTags.length > 0) {
      toast.error('Info not available for Magic Mode images');
      return;
    }

    if (image?.image.isInpainted) {
      toast.error('Info not available for inpainted images');
      return;
    }

    if (!!image?.image.uploadedImageUrl) {
      toast.error('Info not available on variation images');
      return;
    }

    const fakeImage = selectedImageInAlbum || image.image;

    const { prompt, negativePrompt, engine, guidanceScale, seed, style } =
      fakeImage;
    const info = {
      prompt,
      negativePrompt,
      engine: engine.name,
      style: style?.name,
      seed,
      guidanceScale,
    };
    setInfoModalText(info);
    setShowInfoModal(true);
  };

  if (!image) return null;
  const likes = image.amountOfRemixes + (hasUserLiked ? 1 : 0);

  return (
    <>
      <Container>
        <UpvotesContainer
          onClick={handleUpvotingImage}
          hasUserLiked={hasUserLiked}
        >
          <UpvoteIcon hasUserLiked={hasUserLiked} />
          {likes}
        </UpvotesContainer>

        <CommentsButtonAndModal image={image} />

        <InfoButtonContainer onClick={handleClickInfo}>
          <AiOutlineInfoCircle />
        </InfoButtonContainer>

        <RemixButtonContainer onClick={handleClickRemix}>
          <RemixIconNew /> Remix
        </RemixButtonContainer>

        <SaveButtonContainer>
          <SaveButton
            image={image.image}
            handleSaveIsClicked={handleUpvotingImage}
            smaller
            sharedImageRefId={image.id}
          />
        </SaveButtonContainer>
      </Container>

      <ImageInfoModal
        showModal={showInfoModal}
        setShowModal={setShowInfoModal}
        infoText={infoModalText}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;

  margin-top: -2px;

  animation: ${fadeIn} 0.1s ease-in-out;

  ${desktopMediaQuery} {
    max-width: 500px;
    gap: 32px;
    margin-top: 16px;
    margin-left: -32px;
  }
`;

const UpvotesContainer = styled.div<{ hasUserLiked: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ hasUserLiked }) =>
    hasUserLiked ? PRIMARY_COLOR : SECONDARY_TEXT_COLOR};

  ${hoverPointerOnDesktop}
`;

const UpvoteIcon = styled(BiUpvote)<{ hasUserLiked: boolean }>`
  font-size: 22px;
  color: ${({ hasUserLiked }) => (hasUserLiked ? PRIMARY_COLOR : 'white')};
  transition: color 0.3s ease-in-out;
`;

const InfoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-left: 6px;

  ${hoverPointerOnDesktop}
`;

const RemixButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${SECONDARY_TEXT_COLOR};

  ${hoverPointerOnDesktop}
`;

const RemixIconNew = styled(IoShuffleOutline)`
  font-size: 28px;
  color: white;
`;

const SaveButtonContainer = styled.div``;

export default ImageActionsInFeed;
