import { SpeedModeType } from "./types";

export const SLOW_MODE_ENABLED = false;

// TODO: voiko poistaa?
export const FREE_USER_WAIT = 150;
export const FREE_USER_ESTIMATE_ADD = 5;
export const SLOW_MODE_LAG = 10;

export const ADD_WHEN_GENERATING = 35;

export const GOLD_WAIT_TIME = 20;

// Remember to sync with functions/helpers.ts and SpeedModeSelector, HintToRegisterModal
const waitTimeForSpeedModes: { [key: string]: number } = {
  normal: 160,
  fast: 55,
  turbo: 15,
};

export const getWaitTimeForSpeedMode = (speedMode: SpeedModeType) =>
  waitTimeForSpeedModes[speedMode];
