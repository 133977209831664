import React from "react";
import styled from "styled-components";
import { DESKTOP_WIDTH } from "../../constants";

const Terms: React.FC = () => {
  return (
    <Container>
      <h1>Terms and Conditions</h1>

      <h2>Purpose of Service</h2>
      <p>
        Throughout these Terms and Conditions, "you", "your", and "user" refer
        to anyone, whether an individual or a legal entity, who accesses and/or
        uses the OnlyFakes app services. By using OnlyFakes app, you represent
        and warrant that you have the legal capacity to enter into a binding
        agreement.
      </p>

      <p>
        OnlyFakes app, provides a platform for generating and sharing beautiful
        images.
      </p>

      <h2>Privacy</h2>
      <p>
        Only Fakes app Ltd. understands that users may access and use OnlyFakes
        app. The Company takes precautions to protect users' personal data in
        accordance with applicable data protection laws. To exercise your data
        rights, OnlyFakes app can be contacted at support@onlyfakes.app with
        requests or concerns.
      </p>

      <p>
        Only Fakes app Ltd. agrees never to sell or release to any third party
        the private information described below in "Private Information Stored,"
        except in accordance with any public sale, merger, or other acquisition
        of the Company or as required by law. Any subsequent parties who obtain
        said private information through public sale, merger, or acquisition
        shall likewise be bound by the provisions described in these Terms and
        Conditions, or if this agreement cannot be upheld, Only Fakes app Ltd.
        will destroy such private information.
      </p>

      <h2>Private Information Stored</h2>
      <p>
        OnlyFakes app stores the unique inputs provided by each user for each
        prompt, as well as the unique IDs of the user submitting a prompt if
        applicable.
      </p>

      <h2>Intellectual Property Rights</h2>
      <p>
        By using OnlyFakes app, you agree to relinquish all worldwide
        intellectual property rights claims, regardless of legal jurisdiction or
        applicable intellectual property law, including any and all copyright
        claims, to the generated images, artifacts, or any other response
        content created during your use of the app.
      </p>

      <h2>Copyright</h2>
      <p>
        The concept of public domain may differ among legal jurisdictions. By
        using OnlyFakes app, you agree to abide by the terms of the CC0 1.0
        Universal Public Domain Dedication, available{" "}
        <a href="https://creativecommons.org/publicdomain/zero/1.0/">here</a>.
        All users, including "Affirmers" as defined in the Universal Public
        Domain Dedication, explicitly agree to the full terms of this
        dedication.
      </p>

      <h2>Ownership Rights</h2>
      <p>
        You retain ownership rights to any content you submit, post, or display
        on or through OnlyFakes app. Your content includes incorporated audio,
        photos, and videos. However, you must grant certain rights to the
        Company and other users of the service, as outlined below.
      </p>

      <p>
        By submitting, posting, or displaying content on or through OnlyFakes
        app, you grant the Company a perpetual, irrevocable, worldwide,
        transferable, sublicensable, non-exclusive, royalty-free license to use,
        copy, reproduce, process, adapt, modify, publish, transmit, display, and
        distribute such content in any and all media or distribution methods now
        known or later developed. This license authorizes us to make your
        content available to the rest of the world and to let others do the
        same.
      </p>

      <p>
        You agree that this license includes the right for the Company to
        provide, promote, and improve the service and to make content submitted
        to or through OnlyFakes app available to other companies, organizations,
        or individuals for syndication, broadcast, distribution, promotion, or
        publication on other media and services, subject to our terms and
        conditions for such content use. Such additional uses by Only Fakes app
        Ltd. or other companies, organizations, or individuals are made with no
        compensation paid to you with respect to the content that you submit,
        post, transmit, or otherwise make available through the service as your
        use of the service is sufficient compensation for the content and grant
        of rights herein.
      </p>

      <p>
        You also grant each other user of the service a worldwide,
        non-exclusive, royalty-free license to access your content through the
        service and to use that content, including to reproduce, distribute,
        modify, display, and perform it, only as enabled by a feature of the
        service.
      </p>

      <p>
        The license you grant the Company survives any termination, including
        for the reason of content removal, where the operation of the service
        can justify retention or if use of content is permitted to be retained
        before its removal, or where the content finds its way into our training
        data through accidental web-scraping or if the law requires otherwise.
        In any event, removal of content by the user, including opt-out users,
        would not require Only Fakes app Ltd. to: (a) recall content that is
        being used by other users within any viewing functionality of the
        service; or (b) delete copies we reasonably would need to keep for legal
        purposes.
      </p>

      <h2>Waiver of Copyright and Intellectual Property Rights</h2>
      <p>
        By using OnlyFakes app, you waive all worldwide intellectual property
        rights claims, including any and all copyright claims, to the generated
        images, artifacts, or any other response content created during your use
        of the app. All users of OnlyFakes app acknowledge and accept the CC0
        1.0 Universal Public Domain Dedication, which includes a waiver of
        intellectual property rights with respect to any content.
      </p>

      <p>
        OnlyFakes app should not be used in any way that violates applicable
        national, federal, state, local, or international laws or regulations.
        By using OnlyFakes app, you agree to abide by all laws and regulations
        in your use of OnlyFakes app.
      </p>

      <p>
        OnlyFakes app allows you to create and share images using the app's
        features. By generating and sharing content through OnlyFakes app, you
        represent and warrant that you own or have the necessary licenses,
        rights, consents, and permissions to use and authorize Only Fakes app
        Ltd. to use all intellectual property and other proprietary rights in
        and to your generated content, as necessary to provide the OnlyFakes app
        Service.
      </p>

      <p>
        By generating and sharing content through OnlyFakes app, you also
        acknowledge and agree that your generated content may be publicly
        displayed and made available to other users of OnlyFakes app, and that
        you have no expectation of privacy or confidentiality with respect to
        your generated content.
      </p>

      <h2>Indemnification</h2>
      <p>
        By using OnlyFakes app, you agree to indemnify and hold harmless AP
        Software Ltd. and its affiliates, officers, agents, employees, and
        licensors from and against any and all claims, damages, obligations,
        losses, liabilities, costs or debt, and expenses (including but not
        limited to reasonable attorneys' fees) arising from or in connection
        with any intellectual property infringement claims made against AP
        Software Ltd. with respect to your generated content or any other
        violation of these Terms and Conditions. This indemnification obligation
        shall survive termination of your use of OnlyFakes app.
      </p>

      <h2>User-Generated Content</h2>
      <p>
        OnlyFakes app allows you to create and share images using the app's
        features. By generating and sharing content through OnlyFakes app, you
        represent and warrant that you own or have the necessary licenses,
        rights, consents, and permissions to use and authorize Only Fakes app
        Ltd. to use all intellectual property and other proprietary rights in
        and to your generated content, as necessary to provide the OnlyFakes app
        Service.
      </p>

      <p>
        By generating and sharing content through OnlyFakes app, you also
        acknowledge and agree that your generated content may be publicly
        displayed and made available to other users of OnlyFakes app, and that
        you have no expectation of privacy or confidentiality with respect to
        your generated content.
      </p>

      <h2>Choice of Law</h2>
      <p>
        By agreeing, you acknowledge and agree to these Terms and Conditions, as
        well as any other provisions incorporated herein by reference. Use of
        OnlyFakes app without acceptance of these Terms and Conditions
        constitutes unauthorized use, for which you disclaim any and all
        potential or actual right to relief against Only Fakes app Ltd.,
        regardless of Only Fakes app Ltd.'s legal jurisdiction or your domicile,
        residence, or location.
      </p>
      <p>
        Creating anything illegal in the app is prohibited. You agree that any
        creations you make in the app will not be used for any illegal purposes.
        Maker of OnlyFakes app is not responsible for any illegal actions you
        take with the app. Attempting to use the app for illegal results in
        immediate termination of your account and ip ban.
      </p>

      <h2>Fees and Payments</h2>
      <h3>Billing</h3>
      <p>
        You may purchase a subscription to OnlyFakes app by paying a
        subscription fee plus applicable taxes in advance on a monthly basis.
        Tax rates are calculated based on the information you provide and the
        applicable rate at the time of your monthly charge.
      </p>

      <h3>Price and Tax Changes</h3>
      <p>
        OnlyFakes app may from time to time make changes to subscriptions,
        including recurring subscription fees, and will communicate any price
        changes to you in advance. Price changes will take effect at the start
        of the next subscription period following the date of the price change,
        and by continuing to use the OnlyFakes app Service after the price
        change takes effect, you will have accepted the new price. If you do not
        agree to a price change, you can reject the change by unsubscribing from
        the applicable subscription prior to the price change going into effect.
      </p>

      <p>
        OnlyFakes holds the right to change the pricing from subscription to
        other forms of payment, or to change the pricing of the subscription
      </p>

      <p>
        Tax rates are based on the rates applicable at the time of your monthly
        charge. These amounts can change over time with local tax requirements
        in your country, state, territory, county, or city. Any change in tax
        rate will be automatically applied based on the account information you
        provide.
      </p>

      <h3>Renewal and Cancellation</h3>
      <p>
        Your payment to OnlyFakes app will automatically renew at the end of the
        applicable subscription period, unless you cancel your subscription
        before the end of the then-current subscription period. You can cancel
        your subscription anytime via the Manage your subscription link,
        accessible via the Top Bar. Please contact us at (support@onlyfakes.app)
        if you have questions or need help with this. The cancellation will take
        effect the day after the last day of the current subscription period,
        and you will be downgraded to the free version of the OnlyFakes app
        Service. We do not provide refunds or credits for any partial
        subscription periods, except as expressly stated in these Terms.
      </p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1000;
  width: 90%;
  max-width: 340px;
  max-height: 500px;
  word-wrap: break-word;
  wrap: break-word;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    width: 85%;
    padding: 20px;
    max-width: 600px;
    max-height: 800px;
  }
  overflow-y: scroll;
`;
export default Terms;
