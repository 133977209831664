import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreatePageTab } from '../../types';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import MagicModeTab from '../MagicModeTab/MagicModeTab';
import AdvancedModeTab from '../AdvancedModeTab/AdvancedModeTab';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatePageTabs from './CreatePageTabs';
import { useGeneralContext } from '../../context/GeneralContextProvider';

const CreatePage = () => {
  const [selectedTab, setSelectedTab] = useState<CreatePageTab>('magic');

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const modeFromParams = params.get('mode');

  const { activeImage } = useGeneralContext();

  useEffect(() => {
    if (modeFromParams) {
      if (modeFromParams === 'advanced') {
        setSelectedTab('advanced');
      } else if (modeFromParams === 'magic') {
        setSelectedTab('magic');
      }
    }
  }, [modeFromParams]);

  useEffect(() => {
    if (
      activeImage &&
      activeImage.selectedTags &&
      activeImage.selectedTags.length === 0
    ) {
      setSelectedTab('advanced');
    }
    if (modeFromParams) return;
    const lastMode = userSettingsInStorage().lastUsedModeInCreate.get();
    if (lastMode) {
      navigate({ search: `mode=${lastMode}` });
    }
  }, []);

  const handleTabChange = (tab: CreatePageTab) => {
    userSettingsInStorage().lastUsedModeInCreate.set(tab);

    const newParams = new URLSearchParams();
    newParams.set('mode', tab);
    navigate({ search: newParams.toString() });
  };

  return (
    <Container>
      <CreatePageTabs
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {selectedTab === 'magic' ? <MagicModeTab /> : <AdvancedModeTab />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;

  min-height: 100vh;

  margin-top: 0;
  width: 100%;
`;

export default CreatePage;
