import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../../App";
import { headers } from "../../../hooks/misc/helpers";
import { useLoggedInUserContext } from "../../../context/LoggedInUserContextProvider";
import { trackUserVerifiedEmail } from "../../../utils/analytics";

const useVerifyCode = () => {
  const [{ token }] = useCookies(["token"]);
  const { setLoggedInUser } = useLoggedInUserContext();

  const verifyCode = async (
    verificationCode: string
  ): Promise<{ status: number }> => {
    if (!token || !verificationCode) {
      console.error("Missing token or verificationCode");
      return { status: 500 };
    }

    const functionName = "verifyCode";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body: JSON.stringify({ token, verificationCode }),
        headers,
      });

      if (response.status === 200) {
        trackUserVerifiedEmail();
        //set verifiedEmail=true in the state to allow user to claim free credits
        setLoggedInUser((prev) => {
          if (prev) {
            return { ...prev, verifiedEmail: true };
          }
          return prev;
        });
      }

      return { status: response.status };
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      return { status: 500 };
    }
  };

  return verifyCode;
};

export default useVerifyCode;
