import { Routes as Switch, Route } from 'react-router-dom';
import FeedPage from './pages/FeedPage/FeedPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Library from './pages/Library/Library';
import InpaintingPage from './pages/Inpainting/InpaintingPage';
import MainApp from './MainApp';
import LandingPage from './pages/LandingPage/LandingPage';
import NotFound from './components/common/NotFound';
import ContentPolicyPage from './pages/ContentPolicyPage';
import FolderDetail from './pages/Library/FoldersTab/FolderDetail';
import HistoryTab from './pages/Library/HistoryTab';
import SavedImagesTab from './pages/Library/SavedImagesTab';
import FoldersTab from './pages/Library/FoldersTab/FoldersTab';
import Redirecting from './pages/Redirecting/Redirecting';
import ProfileDetail from './pages/ProfilePage/PublicProfile/ProfileDetail';
import CreatePage from './pages/CreatePage/CreatePage';
import HomePage from './pages/HomePage/HomePage';

const Routes = () => {
  return (
    <Switch>
      <Route index element={<LandingPage />} />
      <Route path="/content-policy" element={<ContentPolicyPage />} />
      <Route path="/app" element={<MainApp />}>
        <Route index element={<HomePage />} />

        <Route path="create" element={<CreatePage />} />
          <Route path="inpainting" element={<InpaintingPage />} />
        

        <Route path="library" element={<Library />}>
          <Route path="history" element={<HistoryTab />} />
          <Route path="saved" element={<SavedImagesTab />} />
          <Route path="folders" element={<FoldersTab />} />
          <Route path="folders/:folderId" element={<FolderDetail />} />
        </Route>
        <Route path="feed" element={<FeedPage />} />
        <Route path="profile/:userId" element={<ProfileDetail />} />
        <Route path="profile" element={<ProfilePage />}>
          <Route path="signup" element={<ProfilePage />} />
        </Route>
      </Route>

      <Route path="/redirect" element={<Redirecting />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
