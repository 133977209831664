import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from "../../../constants";

interface P {
  bigger?: boolean;
}
const OnlyFakesLogo = ({ bigger }: P) => (
  <Container>
    <OnlyText bigger={bigger}>Only</OnlyText>
    <FakesText bigger={bigger}>Fakes</FakesText>
    <HeartContainer bigger={bigger}>❤️</HeartContainer>
  </Container>
);

export default OnlyFakesLogo;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  margin-bottom: -8px;
`;

const fontSize = 20;
const biggerFontSize = 36;

const OnlyText = styled.div<P>`
  color: ${PRIMARY_TEXT_COLOR};
  font-size: ${({ bigger }) => (bigger ? biggerFontSize : fontSize)}px;
  font-family: "Poppins", "Open sans", sans-serif;
  font-weight: 300;
`;

const FakesText = styled.div<P>`
  color: ${PRIMARY_COLOR};
  font-size: ${({ bigger }) => (bigger ? biggerFontSize : fontSize)}px;
  font-family: Marguerite1;

  font-weight: 600;
`;

const HeartContainer = styled.div<P>`
  position: relative;
  bottom: ${({ bigger }) => (bigger ? 24 : 12)}px;
  margin-left: ${({ bigger }) => (bigger ? 8 : 4)}px;
  font-size: ${({ bigger }) => (bigger ? biggerFontSize / 3 : fontSize / 3)}px;
`;
