export const LANDING_PAGE_PATH = "/";
export const HOME_PATH = "/app";
export const CREATE_PATH = "/app/create";
export const MAGIC_PATH = "/app/create?mode=magic";
export const ADVANCED_PATH = "/app/create?mode=advanced";
export const FEED_PATH = "/app/feed";
export const LIBRARY_PATH = "/app/library";
export const PROFILE_PATH = "/app/profile";
export const INPAINTING_PATH = "/app/inpainting";
export const FOLDERS_PATH = "/app/library/folders";
