import { useState, useEffect } from "react";

// NOTE: Old stuff, not used

/**
 * Custom React Hook to control the visibility of the navbar based on the scroll position.
 *
 * @returns {Object} - An object containing the `isVisible` state.
 * `isVisible` is a boolean indicating whether the navbar should be visible or not.
 *
 * @example
 * import useHandleControlNavbar from './useHandleControlNavbar';
 *
 * function Component() {
 *   const { isVisible } = useHandleControlNavbar();
 *
 *   return (
 *     <Navbar style={{ display: isVisible ? 'block' : 'none' }} />
 *   );
 * }
 *
 * OR style could be:   top: ${(props) => (props.isVisible ? "0" : "-100px")};
 *                      transition: top 0.3s;
 */
const useHandleControlNavbar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY && window.scrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return { isVisible };
};

export default useHandleControlNavbar;
