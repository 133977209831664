import React, { useEffect } from 'react';
import { SpeedModeType } from '../../../types';
import {
  ContainerForSelect,
  StyledSelectForBelowImage,
} from './SharedComponentsInBelowImage';
import userSettingsInStorage from '../../../localStorage/userSettingsInStorage';
import { FaTachometerAlt } from 'react-icons/fa';

interface Props {
  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;
}

const SpeedModeSelector: React.FC<Props> = ({ speedMode, setSpeedMode }) => {
  useEffect(() => {
    const speedModeFromLocalStorage = userSettingsInStorage().speedMode.get();
    if (speedModeFromLocalStorage) {
      setSpeedMode(speedModeFromLocalStorage as SpeedModeType);
    }
  }, []);

  const handleChange = (e: any) => {
    const newSpeedMode = e.target.value;
    if (!newSpeedMode) {
      console.error('newSpeedMode is undefined');
      return;
    }
    setSpeedMode(newSpeedMode);
    userSettingsInStorage().speedMode.set(newSpeedMode);
  };

  return (
    <ContainerForSelect>
      <FaTachometerAlt size={20} />
      <StyledSelectForBelowImage value={speedMode} onChange={handleChange}>
        <option value={'normal'}>Normal - 0 credits ~160 sec</option>
        <option value={'fast'}>Fast - 1 credit ~55 sec</option>
        <option value={'turbo'}>Turbo - 3 credits ~15 sec</option>
      </StyledSelectForBelowImage>
    </ContainerForSelect>
  );
};

export default SpeedModeSelector;
