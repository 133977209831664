import { getBaseUrlForFetching, isLocalhost } from '../../App';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { LoggedInUser } from '../../types';
import {
  trackGoldUserInfoFetched,
  trackUserDataHasBeenFetched,
  trackUserWithMoreThan100CreditsFetched,
} from '../../utils/analytics';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import { headers } from '../misc/helpers';
import useSetUserCookie from './useSetUserCookie';

interface ResultReturn {
  data: {
    user: LoggedInUser;
    token?: string;
  };
}

// Not needed at the moment
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkIfRecentlyLostGoldStatus = (user: LoggedInUser) => {
  // if lastPaymentDate is between 31 and 34 days ago, it means the user has lost gold status recently
  // also check if localStorage already had the key, if it did, it means the user has already been alerted
  if (!user?.lastPaymentDate) {
    return false;
  }
  const hasBeenAlerted = localStorage.getItem(
    'hasBeenAlertedForGoldStatus27May',
  );
  if (hasBeenAlerted) {
    return false;
  }

  const lastPaymentDate = new Date(user.lastPaymentDate);
  const today = new Date();
  const daysSinceLastPayment = Math.floor(
    (today.getTime() - lastPaymentDate.getTime()) / (1000 * 60 * 60 * 24),
  );
  if (daysSinceLastPayment > 31 && daysSinceLastPayment <= 47) {
    localStorage.setItem('hasBeenAlertedForGoldStatus27May', 'true');
    return true;
  }
};

/**
 * This hook is used to get the user from the server
 *
 * Also sets the user and saved images in the context
 */
const useHandleGetUser = () => {
  const { setLoggedInUser } = useLoggedInUserContext();
  const { setShowConfirmEmailModal } = useGeneralContext();
  const { setIsLoadingLogin } = useLoggedInUserContext();

  const setUserCookie = useSetUserCookie();

  const { setSpeedMode } = useImageGenerationSettingsContext();

  /**
   * This function is used to migrate the user to the new savedImages collection
   * This is needed because the user might have saved images before the new collection was created
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMigrationToNewSavedCollection = async (user: LoggedInUser) => {
    // If user has saved Images, it means the user has migrated to the new savedImages collection
    if (
      user.hasMigratedToNewSavedCollection ||
      user.isMigratingToNewSavedCollection
    ) {
      return;
    }

    if (isLocalhost) return;

    const functionName = 'migrateToNewSavedCollection-background';
    const baseUrl = getBaseUrlForFetching();
    const migrateToNewSavedCollectionUrl = `${baseUrl}/${functionName}`;

    const callMigrateToNewSavedCollection = async () =>
      await fetch(migrateToNewSavedCollectionUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({ userId: user.id }),
      });

    callMigrateToNewSavedCollection();

    alert(
      "We're currently upgrading our image storage system. You might experience a brief period where saved images are temporarily unavailable or partially displayed. This should only take a few moments. Thanks for your patience",
    );
  };

  const handleCheckingForNewAuthMethod = async (user: LoggedInUser) => {
    if (!user.verifiedEmail) {
      setShowConfirmEmailModal(true);
    }
  };

  /**
   *
   * @param token from cookie; has userId and type
   * Handles fetching the user from the server and setting in the context.
   * Also does some small other things like setting the speed mode based on user credits
   */
  const handleGetUser = async (token: string) => {
    // if in root, return early (this is the landing page, not needed to login yet)
    if (window.location.pathname === '/') {
      return;
    }
    if (!token) {
      return;
    }
    setIsLoadingLogin(true);
    const functionName = 'getUser';
    const baseUrl = getBaseUrlForFetching();
    const getUserUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(getUserUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({ token }),
    });

    if (response.status === 200) {
      const result: ResultReturn = await response.json();
      const data = result.data;

      // if result.token exists, it means the user type was changed after last login and cookie needs to be updated
      if (data.token) {
        setUserCookie(data.token);
      }

      const user = data.user;
      if (user?.type === 'gold') {
        userSettingsInStorage().speedMode.set('turbo');
        setSpeedMode('turbo');
        trackGoldUserInfoFetched();
      }

      if (user?.credits && user.credits > 100) {
        trackUserWithMoreThan100CreditsFetched();
      }

      const userSettingInStorage = userSettingsInStorage().speedMode.get();

      const isUserSettingNormal = userSettingInStorage === 'normal';
      const isUserSettingFast = userSettingInStorage === 'fast';
      const isFastOrNormal = isUserSettingNormal || isUserSettingFast;

      if (user?.credits && user.credits > 2 && !isFastOrNormal) {
        setSpeedMode('turbo');
      }

      trackUserDataHasBeenFetched();

      //handleMigrationToNewSavedCollection(user);

      handleCheckingForNewAuthMethod(user);

      setLoggedInUser(user);

      /*
      if (checkIfRecentlyLostGoldStatus(user)) {
        alert(
          'Your Gold Subscription ⭐ has expired. Auto-renewals were turned off due to a payment processor change. Sorry! 🙏 Please renew your subscription from the menu in the top-right corner using PayPal, Google Pay, Apple Pay, or other available options to continue enjoying Gold benefits.',
        );
      }
        */
    }
    setIsLoadingLogin(false);
  };

  return handleGetUser;
};

export default useHandleGetUser;
