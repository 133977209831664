import styled from 'styled-components';
import { FakeImage } from '../../types';
import { SmoothlyLoadingImage } from '../../components/common/SmoothlyLoadingImage';
import { useState } from 'react';
import { imageHoverAnimationInFeed } from '../../styleHelpers';
import { FaRetweet, FaUpload, FaUsers } from 'react-icons/fa6';
import { FaMagic, FaPaintBrush } from 'react-icons/fa';

interface ImageTemplateNewProps {
  image: FakeImage;
}

const FeedImage = ({ image }: ImageTemplateNewProps) => {
  const [loaded, setLoaded] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const imageUrl = image?.imageUrl;

  const isFromFeed = image.sharedImageRefId;
  const isInpainted = image.isInpainted;
  const isVariation = !!image.isCreatingVariations;
  const isMagicMode = image?.selectedTags && image?.selectedTags?.length > 0;
  const isImg2Img = !!image?.uploadedImageUrl && !isVariation && !isMagicMode;

  const showBadge =
    isFromFeed || isInpainted || isImg2Img || isVariation || isMagicMode;

  const handleImageLoadingError = () => {
    setHideImage(true);
  };

  if (!imageUrl || hideImage) return null;

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Image
        src={
          imageUrl ||
          'https://excellance.com/wp-content/plugins/photonic/include/images/placeholder-Sm.png'
        }
        alt="Loading..."
        loading="lazy"
        loaded={loaded}
        onLoad={() => setLoaded(true)}
        onError={handleImageLoadingError}
      />

      {showBadge && (
        <ImageBadgeContainer>
          {isFromFeed && <FaUsers size={12} />}
          {isInpainted && <FaPaintBrush size={12} />}
          {isImg2Img && <FaUpload size={12} />}
          {isVariation && <FaRetweet size={12} />}
          {isMagicMode && <FaMagic size={12} />}
        </ImageBadgeContainer>
      )}
    </Container>
  );
};

export default FeedImage;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 300px;
`;

const Image = styled(SmoothlyLoadingImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${imageHoverAnimationInFeed};
`;

const ImageBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px;
  font-size: 12px;
  border-radius: 0 10px 0 0;
  cursor: default;
`;
