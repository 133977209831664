import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { desktopMediaQuery, getIsOnDesktop } from '../../../styleHelpers';
import { SharedImage } from '../../../types';
import InfiniteImageGrid from './InfiniteImageGrid';
import ImageInGrid from './ImageInGrid';
import { useImagesContext } from '../../../context/ImagesContextProvider';
import { waitSeconds } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../../pathNames';
import LoadingAnimation from '../../common/LoadingStuff/LoadingAnimation';
import { FiFilter } from 'react-icons/fi';

interface Props {
  sharedImages: SharedImage[];
  shouldMarkAsSeen?: boolean; // This is used for 'hot' sorting to filter out images that have already been seen by the user
  blockFromScrolling?: boolean;
}
const SharedImagesGrid = ({
  sharedImages,
  shouldMarkAsSeen,
  blockFromScrolling,
}: Props) => {
  const [items, setItems] = useState<SharedImage[]>([]);
  const {
    indexOfLastImage,
    locationWhenLastImageClicked,
    setIndexOfLastImage,
    setLocationWhenLastImageClicked,
    setAdditionalImagesInShowMore,
  } = useImagesContext();

  const navigate = useNavigate();

  const SHOW_X_PER = 15;
  useEffect(() => {
    setItems(sharedImages.slice(0, SHOW_X_PER));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedImages]);

  const fetchMore = () => {
    const nextItems = sharedImages.slice(
      items.length,
      items.length + SHOW_X_PER,
    );
    setItems(items.concat(nextItems));
  };

  const hasMore = items.length < sharedImages.length;
  const dataLength = items.length;

  const isOnDesktop = getIsOnDesktop();

  useEffect(() => {
    const scrollIntoView = async () => {
      if (blockFromScrolling) return;
      const currentPath = window.location.pathname;
      const isSamePathAsLastTime = currentPath === locationWhenLastImageClicked;

      if (indexOfLastImage && sharedImages.length > 0 && isSamePathAsLastTime) {
        const gridContainer = document.getElementById('sharedImagesGrid');
        gridContainer?.setAttribute('style', 'opacity: 0');

        setItems(sharedImages.slice(0, indexOfLastImage + SHOW_X_PER));

        const idOfLast = sharedImages[indexOfLastImage]?.id;
        await waitSeconds(0.05);

        const lastImageElemt = document.getElementById(idOfLast);
        lastImageElemt?.scrollIntoView();

        const isProfile = currentPath.includes('profile');
        const scrollDownBy = isProfile ? -80 : -120;
        window.scrollBy(0, scrollDownBy);

        gridContainer?.setAttribute('style', 'opacity: 1');
      }
    };
    requestAnimationFrame(scrollIntoView);
  }, [sharedImages]);

  const handleClickImageOpen = (image: SharedImage, index: number) => {
    setIndexOfLastImage(index);
    const pathLocation = window.location.pathname;
    setLocationWhenLastImageClicked(pathLocation);
    const HOW_MANY_TO_SHOW = 90;
    setAdditionalImagesInShowMore(
      sharedImages.slice(index + 1, index + HOW_MANY_TO_SHOW),
    );

    const urlToNavigate = `${FEED_PATH}?id=${image.id}&imageUrl=${image.image.imageUrl}`;

    // FeedImagePage.tsx
    navigate(urlToNavigate);
  };

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // if sharedImages.lenght is 0, wait 1 second before not showing the loading spinner
    if (sharedImages.length === 0) setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, [sharedImages]);

  if (sharedImages.length === 0)
    return showLoading ? (
      <LoadingAnimation loaderToChoose={5} loading />
    ) : (
      <Container>
        No images. Try changing #channel or filter <StyledFilterIcon />
      </Container>
    );

  return (
    <Container id="sharedImagesGrid">
      <InfiniteImageGrid
        dataLength={dataLength}
        fetchMore={fetchMore}
        hasMore={hasMore}
        isOnDesktop={isOnDesktop}
      >
        {items.map((image, index) => (
          <ImageInGrid
            key={image.id}
            image={image}
            index={index}
            handleClickImageOpen={handleClickImageOpen}
            shouldMarkAsSeen={shouldMarkAsSeen}
          />
        ))}
      </InfiniteImageGrid>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  overflow: auto;

  transition: opacity 0.2s;

  ${desktopMediaQuery} {
    max-width: 80vw;
    max-width: 1075px;
    margin-left: 15vw;
    // hide scroll bars
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  padding-bottom: 80px;
`;


const StyledFilterIcon = styled(FiFilter)`
  margin-top: 4px;
`;

export default SharedImagesGrid;
