import React, { useEffect } from 'react';
import styled from 'styled-components';
import CreateImagesCTAButton from './CreateImagesCTAButton';
import HomePageImageFeed from './HomePageImageFeed';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import OnlyFakesLogo from '../../components/common/TopBar/OnlyFakesLogo';
import LoginButton from './LoginButton';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import NotificationBar from './HomePageNotification/NotificationBar';
import { scrollToTop } from '../../hooks/misc/helpers';

const HomePage: React.FC = () => {
  const { loggedInUser, isLoadingLogin } = useLoggedInUserContext();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <OuterContainer>
      <NotificationBar />
      <TopPartContainer>
        <OnlyFakesLogo bigger />
      </TopPartContainer>

      <MiddlePartContainer>
        <CreateImagesCTAButton />
        {!loggedInUser && <LoginButton />}
        <OrBrowseText />
      </MiddlePartContainer>

      {isLoadingLogin ? (
        <LoadingAnimation
          loaderToChoose={2}
          loading={true}
          style={{ marginTop: '80px' }}
        />
      ) : (
        <HomePageImageFeed />
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 120px;
`;

const TopPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  margin-top: 32px;
`;

const MiddlePartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 24px;
`;

const OrBrowseText = () => {
  return (
    <OrBrowseTextContainer>
      <ShortLine />
      or explore images below
      <ShortLine />
    </OrBrowseTextContainer>
  );
};

const OrBrowseTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: 500;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
`;

const ShortLine = styled.div`
  width: 50px;
  height: 2px;
  background-color: ${SECONDARY_TEXT_COLOR};
  margin: 0 auto;
`;

export default HomePage;
