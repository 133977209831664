import { createContext, useContext, useState } from 'react';
import { FakeImage, GenericSetState } from '../types';

interface IGeneralContext {
  loading: boolean;
  setLoading: GenericSetState<boolean>;

  images: FakeImage[];
  setImages: GenericSetState<FakeImage[]>;
  activeImage: FakeImage;
  setActiveImage: GenericSetState<FakeImage>;

  showSomethingWentWrong: boolean;
  setShowSomethingWentWrong: GenericSetState<boolean>;

  isSeedFrozen: boolean;
  setIsSeedFrozen: GenericSetState<boolean>;

  queueTime: number;
  setQueueTime: GenericSetState<number>;

  cancelled: boolean;
  setCancelled: GenericSetState<boolean>;

  generationStartedTimestamp: number;
  setGenerationStartedTimestamp: GenericSetState<number>;

  generationReadyTimestamp: number;
  setEstimateGenerationReadyTimestamp: GenericSetState<number>;

  showTermsModal: boolean;
  setShowTermsModal: GenericSetState<boolean>;

  showConfirmEmailModal: boolean;
  setShowConfirmEmailModal: GenericSetState<boolean>;

  isFirstTimeUser: boolean;
  setIsFirstTimeUser: GenericSetState<boolean>;

  // use to get the first fast generation
  firstTimeUserToken: string;
  setFirstTimeUserToken: GenericSetState<string>;

  hasGeneratedAnImage: boolean;
  setHasGeneratedAnImage: GenericSetState<boolean>;

  showTopRightMenu: boolean;
  setShowTopRightMenu: GenericSetState<boolean>;
  goldModalOpen: boolean;
  setGoldModalOpen: GenericSetState<boolean>;

  showCreateNewPostModal: boolean;
  setShowCreateNewPostModal: GenericSetState<boolean>;
}

const defaultState = {
  loading: false,
  setLoading: undefined as unknown as GenericSetState<boolean>,
  images: [],
  setImages: undefined as unknown as GenericSetState<FakeImage[]>,
  activeImage: {} as FakeImage,
  setActiveImage: undefined as unknown as GenericSetState<FakeImage>,

  showBrowseModal: false,
  setShowBrowseModal: undefined as unknown as GenericSetState<boolean>,

  showSomethingWentWrong: false,
  setShowSomethingWentWrong: undefined as unknown as GenericSetState<boolean>,

  isSeedFrozen: false,
  setIsSeedFrozen: undefined as unknown as GenericSetState<boolean>,

  queueTime: 15,
  setQueueTime: undefined as unknown as GenericSetState<number>,

  cancelled: false,
  setCancelled: undefined as unknown as GenericSetState<boolean>,

  generationStartedTimestamp: Date.now(),
  setGenerationStartedTimestamp:
    undefined as unknown as GenericSetState<number>,

  generationReadyTimestamp: Date.now() + 10,
  setEstimateGenerationReadyTimestamp:
    undefined as unknown as GenericSetState<number>,

  showTermsModal: false,
  setShowTermsModal: undefined as unknown as GenericSetState<boolean>,

  showConfirmEmailModal: false,
  setShowConfirmEmailModal: undefined as unknown as GenericSetState<boolean>,

  isFirstTimeUser: false,
  setIsFirstTimeUser: undefined as unknown as GenericSetState<boolean>,

  firstTimeUserToken: '',
  setFirstTimeUserToken: undefined as unknown as GenericSetState<string>,

  hasGeneratedAnImage: false,
  setHasGeneratedAnImage: undefined as unknown as GenericSetState<boolean>,

  showTopRightMenu: false,
  setShowTopRightMenu: undefined as unknown as GenericSetState<boolean>,
  goldModalOpen: false,
  setGoldModalOpen: undefined as unknown as GenericSetState<boolean>,

  showCreateNewPostModal: false,
  setShowCreateNewPostModal: undefined as unknown as GenericSetState<boolean>,
};

const GeneralContext = createContext<IGeneralContext>(defaultState);

export const useGeneralContext = () => useContext(GeneralContext);

// @ts-ignore children does actually exist, todo figure types?
const GeneralContextProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<FakeImage[]>([]);
  const [activeImage, setActiveImage] = useState<FakeImage>({} as FakeImage);
  const [showSomethingWentWrong, setShowSomethingWentWrong] = useState(false);

  const [isSeedFrozen, setIsSeedFrozen] = useState(false);

  const [queueTime = 15, setQueueTime] = useState<number>(15);

  const [cancelled, setCancelled] = useState(false);

  const [generationStartedTimestamp, setGenerationStartedTimestamp] = useState(
    Date.now(),
  );
  const [generationReadyTimestamp, setEstimateGenerationReadyTimestamp] =
    useState(Date.now() + 10);

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);

  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);
  const [firstTimeUserToken, setFirstTimeUserToken] = useState('');
  const [hasGeneratedAnImage, setHasGeneratedAnImage] = useState(false);

  const [showTopRightMenu, setShowTopRightMenu] = useState(false);
  const [goldModalOpen, setGoldModalOpen] = useState(false);

  const [showCreateNewPostModal, setShowCreateNewPostModal] = useState(false);

  return (
    <GeneralContext.Provider
      // TODO: Figure out if needed to wrap in useMemo
      // https://stackoverflow.com/questions/62230532/is-usememo-required-to-manage-state-via-the-context-api-in-reactjs
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        loading,
        setLoading,
        images,
        setImages,
        activeImage,
        setActiveImage,

        showSomethingWentWrong,
        setShowSomethingWentWrong,

        isSeedFrozen,
        setIsSeedFrozen,

        queueTime,
        setQueueTime,

        cancelled,
        setCancelled,

        generationStartedTimestamp,
        setGenerationStartedTimestamp,
        generationReadyTimestamp,
        setEstimateGenerationReadyTimestamp,

        showTermsModal,
        setShowTermsModal,

        showConfirmEmailModal,
        setShowConfirmEmailModal,

        isFirstTimeUser,
        setIsFirstTimeUser,
        firstTimeUserToken,
        setFirstTimeUserToken,
        hasGeneratedAnImage,
        setHasGeneratedAnImage,

        showTopRightMenu,
        setShowTopRightMenu,
        goldModalOpen,
        setGoldModalOpen,

        showCreateNewPostModal,
        setShowCreateNewPostModal,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
